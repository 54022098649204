@tailwind base;
@tailwind components;
@tailwind utilities;

.datatable-templating .table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.p-password,.p-password-input	{
  width: 100% !important;
}